<template>
  <div class="ms_main_wrapper">
    <app-side-menu />
    <app-header />
    <Suspense>
      <template #default>
        <div class="ms_content_wrapper padder_top60">
          <app-poets/>
        </div>
      </template>
      <template #fallback>
        <app-loader/>
      </template>
    </Suspense>
    <app-bottom-navbar/>
  </div>
</template>

<script>
import AppHeader from "../../components/Include/AppHeader.vue";
import AppSideMenu from "../../components/Include/AppSideMenu.vue";
import { defineAsyncComponent, Suspense } from "vue";
import AppLoader from '../../components/Spinners/AppLoader.vue';
import AppBottomNavbar from '../../components/Include/AppBottomNavbar.vue';
const AppPoets = defineAsyncComponent(() =>
  import("../../components/Pages/AppPoets.vue")
);
export default {
  name: 'Poets',
  components: { AppSideMenu, AppHeader, Suspense, AppLoader, AppBottomNavbar, AppPoets },
}
</script>

<style>

</style>